import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import {
    ChatBubbleLeftEllipsisIcon,
    ShareIcon,
} from "@heroicons/react/24/outline";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/20/solid";

import { BookMark } from "./bookmark";
import { Likes } from "./likes";
import { apiRequest, fetchComments } from "../../api/member-api-calls";

export const SocialHubPost = ({
    member,
    setMember,
    data,
    setIsSharePostModalOpen,
    setIsViewPostModalOpen,
    setSelectedPost,
    postId,
    posts,
    setPosts,
    getSocialPosts,
    setIsEditPost,
    setIsAddPostModalOpen,
}) => {
    const [comments, setComments] = useState([]);

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        fetchComments(data.id, setComments);
    }, []); //eslint-disable-line

    const deletePost = () => {
        const newPosts = posts.filter((post) => post.id !== postId);

        setPosts([...newPosts]);

        enqueueSnackbar("Post deleted succesfully", {
            variant: "error",
            anchorOrigin: {
                horizontal: "center",
                vertical: "bottom",
            },
            action: (
                <button
                    className="px-2 py-1 rounded bg-white text-black"
                    onClick={() => {
                        setPosts([...posts]);
                    }}
                >
                    Undo
                </button>
            ),
            onClose: () => {
                apiRequest(
                    "post",
                    "socialpost/delete/" + postId,
                    enqueueSnackbar,
                    "Post deleted successfully"
                ).then(() => getSocialPosts());
            },
        });
    };

    const formatPost = (post) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return post.replace(
            urlRegex,
            (url) =>
                `<a href="${url}" class="text-blue-600" target="_blank" rel="noopener noreferrer">${url}</a>`
        );
    };

    return (
        <div className="rounded-lg shadow-lg px-5 pt-5 pb-2 mb-2 bg-white">
            <div className="flex justify-between">
                <div className="flex items-center space-x-6">
                    <img
                        src={`${process.env.REACT_APP_IMAGEKIT_URL}members/${data.logo}`}
                        alt="member logo"
                        className="h-9 w-auto"
                    />
                    <div>
                        <p className="capitalize text-lg font-semibold">
                            {data.author}
                        </p>
                        <p className="capitalize text-md text-gray-600 font-semibold">
                            {data.company}
                        </p>
                    </div>
                </div>
                <div className="flex items-center space-x-4">
                    {member.id !== "" && (
                        <BookMark
                            member={member}
                            setMember={setMember}
                            id={data.id}
                        />
                    )}

                    {member.id === data.created_by && setSelectedPost && (
                        <>
                            <PencilSquareIcon
                                className="h-6 w-5 text-black cursor-pointer hover:text-green-600 transition-all duration-300"
                                onClick={() => {
                                    setSelectedPost(data);
                                    setIsEditPost(true);
                                    setIsAddPostModalOpen(true);
                                }}
                            />
                            <TrashIcon
                                className="w-6 text-red-600 hover:text-black cursor-pointer transition-all duration-300"
                                onClick={() => deletePost()}
                            />
                        </>
                    )}
                </div>
            </div>
            <div
                className="px-2 py-2 my-3 rounded-lg cursor-pointer"
                onClick={() => {
                    if (setSelectedPost) {
                        setSelectedPost(data);
                        setIsViewPostModalOpen(true);
                    }
                }}
            >
                <p
                    className="rounded-lg w-full"
                    dangerouslySetInnerHTML={{
                        __html: formatPost(data.post),
                    }}
                />
                {data.image !== "" && (
                    <img
                        src={`${process.env.REACT_APP_IMAGEKIT_URL}socialpost/${data.image}`}
                        alt="post"
                        className="rounded-lg my-3 w-full"
                    />
                )}
            </div>
            <div className="flex justify-between">
                <div className="flex gap-2 justify-center items-center">
                    <div className="flex space-x-2 w-fit justify-center items-center">
                        {setSelectedPost && (
                            <>
                                <ChatBubbleLeftEllipsisIcon
                                    className="w-6 text-black cursor-pointer"
                                    onClick={() => {
                                        if (
                                            member.id !== "" &&
                                            member.id !== null
                                        ) {
                                            setSelectedPost(data);
                                            setIsViewPostModalOpen(true);
                                        }
                                    }}
                                />
                                <span className="flex items-center justify-center">
                                    {comments.length}
                                </span>
                            </>
                        )}
                    </div>
                    <Likes
                        id={data.id}
                        post={data.post}
                        likes={data.likes}
                        member={member}
                        setMember={setMember}
                    />
                </div>
                <div className="flex space-x-2 w-full justify-end">
                    {setSelectedPost && (
                        <ShareIcon
                            className="w-6 -mt-1 cursor-pointer"
                            onClick={() => {
                                setSelectedPost(data);
                                setIsSharePostModalOpen(true);
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
