import { useState } from "react";
import { Page } from "../../layouts/page";

export const PrivacyPolicyPage = () => {
    const [data, updateData] = useState([]);
    return (
        <Page
            title="Privacy Policy"
            description=" This Privacy Policy explains how The Clean Cooking Association of Kenya (CCAK) collects, uses, and protects your personal information. By using our services and website, you agree to the terms outlined in this policy."
            page="privacy-policy"
            data={data}
            updateData={updateData}
        >
            <div className="pt-[3.8rem] lg:pt-[6.6rem]">
                <div className="p-6 lg:p-24">
                    <h1 className="text-3xl font-bold my-3">Privacy Policy</h1>
                    <p>
                        This Privacy Policy explains how The Clean Cooking
                        Association of Kenya (CCAK) collects, uses, and protects
                        your personal information. By using our services and
                        website, you agree to the terms outlined in this policy.
                    </p>
                    <h2 className="text-xl font-bold my-3">
                        Information Collection
                    </h2>
                    <p>
                        We may collect the following types of personal
                        information from you:
                    </p>
                    <ul className="font-manjari space-y-4 list-disc px-10">
                        <li>Name, contact details, and job title</li>
                        <li>Demographic information</li>
                        <li>Payment information</li>
                        <li>
                            Information you provide during membership
                            registration
                        </li>
                    </ul>
                    <h2 className="text-xl font-bold my-3">
                        Use of Information
                    </h2>
                    <p>
                        We use your personal information for the following
                        purposes:
                    </p>
                    <ul className="font-manjari space-y-4 list-disc px-10">
                        <li>Processing membership applications and renewals</li>
                        <li>Managing and organizing events and forums</li>
                        <li>Improving our services and communication</li>
                        <li>Sending you newsletters and updates</li>
                        <li>Complying with legal obligations</li>
                    </ul>
                    <h2 className="text-xl font-bold my-3">
                        Information Sharing
                    </h2>
                    <p>
                        We do not sell, trade, or rent your personal information
                        to third parties. However, we may share your information
                        with partners and service providers who assist us in
                        accomplishing the purposes mentioned above.
                    </p>
                    <h2 className="text-xl font-bold my-3">Security</h2>
                    <p>
                        We take reasonable precautions to protect your personal
                        information from unauthorized access, use, or
                        disclosure. However, no data transmission over the
                        internet or electronic storage method is 100% secure.
                    </p>
                    <h2 className="text-xl font-bold my-3">Cookies</h2>
                    <p>
                        Our website uses cookies to enhance your experience and
                        analyze user behavior. You can modify your browser
                        settings to decline cookies, but this may affect certain
                        features of our website.
                    </p>
                    <h2 className="text-xl font-bold my-3">Your Rights</h2>
                    <p>
                        You have the right to access, correct, or delete your
                        personal information. If you have any questions or wish
                        to exercise your rights, please contact us.
                    </p>
                    <p>
                        For more detailed information about our privacy
                        practices, please review our full Privacy Policy
                        available on our website.
                    </p>
                </div>
            </div>
        </Page>
    );
};
